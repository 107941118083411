<template>
    <div v-if="isVisible" class="modal" >
      <div class="modal-content" >
        <h2>일정 수정</h2>
        <form @submit.prevent="submitForm">
          <!-- 제목 -->
          <label for="title">제목:</label>
          <input v-model="form.title" type="text" id="title" />
  
          <!-- 시작일시 -->
          <label for="start">시작일시:</label>
          <input v-model="form.start" type="datetime-local" id="start" />
  
          <!-- 종료일시 -->
          <label for="end">종료일시:</label>
          <input v-model="form.end" type="datetime-local" id="end" @blur="validateEndDate" />
  
          <!-- 배경색상 선택 -->
          <label>배경색상 선택:</label>
          <div class="color-buttons">
            <button
              v-for="(color, index) in colorOptions"
              :key="index"
              :class="{ selected: form.backgroundColor === color.value }"
              :style="{ backgroundColor: color.value }"
              class="color-btn"
              @click.prevent="setBackgroundColor(color.value)"
            ></button>
            <div
              class="color-preview"
              :style="{ backgroundColor: form.backgroundColor || '#2396f2', color: '#ffffff' }"
            >
              선택 미리보기
            </div>
          </div>
  
          <!-- 내용 -->
          <label for="content">내용:</label>
          <textarea v-model="form.content" id="content"></textarea>
  
          <!-- 참석자 -->
          <label for="attendees">참석자:</label>
          <div class="attendees-section">
            <input
              v-model="attendeesDisplay"
              type="text"
              id="attendees"
              placeholder="참석자 입력"
              readonly
            />
            <button type="button" @click="openBatchModal" class="batch-btn">기수별</button>
          </div>
  
          <!-- 기수별 선택 모달 -->
          <div v-if="showBatchModal" class="batch-modal-wrapper">
            <div class="batch-modal-content">
              <h3 class="modal-title">기수별 사용자 선택/해제</h3>
              <ul class="batch-list">
                <li
                  v-for="(group, batch) in groupedByBatchSample"
                  :key="batch"
                  class="batch-item"
                  :class="{ selected: selectedBatches === batch }"
                  @click="toggleBatchSelection(batch)"
                >
                  <span>{{ batch }}</span>
                </li>
              </ul>
              <button class="close-btn" @click="closeBatchModal">닫기</button>
            </div>
          </div>
  
          <!-- SMS 발송 여부 -->
          <div class="sms-toggle">
            <label>SMS 발송 여부:</label>
            <label class="switch">
              <input type="checkbox" v-model="form.sms" @change="validateSmsToggle" />
              <span class="slider"></span>
            </label>
          </div>
  
          <!-- 발송문자 템플릿 및 예약 발송 -->
          <div v-if="form.sms">
            <label for="sms-template">발송문자 템플릿:</label>
            <select
              id="sms-template"
              v-model="form.templateID"
              :disabled="!form.attendees.length"
              @change="updateMessageBody"
            >
              <option value="" disabled selected>템플릿을 선택하세요</option>
              <option v-for="template in messages" :key="template.MSG_UUID" :value="template.MSG_UUID">
                {{ template.MSG_CONTENT }}
              </option>
            </select>


            <label for="sendDateTime">발송일시:</label>
            <input
              id="send_time"
              type="datetime-local"
              v-model="form.send_time"
              :disabled="!form.templateID || !form.attendees.length"
              @change="validateSendDateTime"
            />
          </div>
  
          <!-- 수정, 삭제, 닫기 버튼 -->
          <div class="modal-buttons">
            <button type="submit">수정</button>
            <button type="button" class="delete-button" @click="deleteEvent">삭제</button>
            <button type="button" @click="closeModal">닫기</button>
          </div>
        </form>
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  
  export default {
    props: ["isVisible", "event"],
    data() {
      return {
        form: {
          id: "",
          title: "",
          start: "",
          end: "",
          content: "",
          sms: false,
          templateID: null,
          send_time: "",
          attendees: [],
          backgroundColor: "#2396f2",
          batch:''
        },
        colorOptions: [
            { name: '초록색', value: '#59b95a' },
            { name: '파란색', value: '#2396f2' },
            { name: '빨간색', value: '#dc3545' },
            { name: '노란색', value: '#ffc107' },
            { name: '보라색', value: '#6f42c1' },
            { name: '주황색', value: '#fd5722' },
            { name: '갈색', value: '#765743' },
        ],
        showBatchModal: false,
        groupedByBatchSample: [],
        selectedBatches: "",
        messages: [],
      };
    },
    watch: {
      event: {
        handler(newEvent) {
          console.log('Watch detected event change:', newEvent); // 디버깅용 로그
          console.log("Before assigning batch:", newEvent.extendedProps?.batch);
          const toLocalDatetime = (utcDate) => {
            if (!utcDate) return ''; // 방어 코드 추가
            const localDate = new Date(utcDate);
            localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
            return localDate.toISOString().slice(0, 16); // yyyy-MM-ddTHH:mm 형식
          };

          if (newEvent) {
            console.log("Watch detected event change:", newEvent);
            this.form = {
              ...this.form,
              id: newEvent.id || '',
              title: newEvent.title || '',
              start: newEvent.start ? toLocalDatetime(newEvent.start) : '',
              end: newEvent.end ? toLocalDatetime(newEvent.end) : '',
              content: newEvent.extendedProps?.content || '',
              attendees: Array.isArray(newEvent.extendedProps?.attendees)
                ? newEvent.extendedProps.attendees
                : [],
              msg_body: newEvent.extendedProps?.msg_body || '',
              sms: newEvent.extendedProps?.sms || false,
              
              templateID: newEvent.extendedProps?.template || '',
              send_time: newEvent.extendedProps?.sendTime ? toLocalDatetime(newEvent.extendedProps.sendTime) : '',
              batch: newEvent.extendedProps?.batch || '', 
              backgroundColor: newEvent.backgroundColor || '#2396f2',
              
            };
             // ✅ batch 값이 변경될 때 selectedBatches 업데이트
            this.selectedBatches = newEvent.extendedProps?.batch || ''; 
            // Vue의 반응성 문제 해결을 위해 msg_body 강제 업데이트
            //this.$set(this.form, 'msg_body', newEvent.extendedProps?.msg_body || '');
            //this.updateMessageBody();
            console.log("After assigning batch:", this.form.batch);
            console.log("Updated form.msg_body:", this.form.msg_body);
          }
        },
        immediate: true,
      },
    },

    async mounted() {
        await this.fetchTemplate();
        await this.fetchBatchData();

        if (this.event) {
          console.log('Initializing form in mounted:', this.event); // 디버깅용 로그
          // const toLocalDatetime = (utcDate) => {
          //     const localDate = new Date(utcDate);
          //     localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
          //     return localDate.toISOString().slice(0, 16); // "yyyy-MM-ddTHH:mm" 형식 반환
          // };
          this.form = {
            id: this.event.id || '',
            title: this.event.title || '',       
            start: this.convertUtcToKst(this.event.startStr), // UTC -> KST 변환
            end: this.convertUtcToKst(this.event.endStr), // UTC -> KST 변환
            content: this.event.extendedProps?.content || '',
            msg_body:this.event.extendedProps?.msg_body|| '',
            sms: this.event.extendedProps?.sms || false,
            templateID: this.event.extendedProps?.templateID || '',
            send_time: this.event.extendedProps?.sendTime ? this.convertUtcToKst(this.event.extendedProps.sendTime) : '',
            attendees: Array.isArray(this.event.extendedProps?.attendees)
              ? this.event.extendedProps.attendees
              : [], // attendees 초기화
            batch: this.event.extendedProps?.batch || '',
            backgroundColor: this.event.backgroundColor || '#2396f2',
            };
        }
        //this.updateMessageBody();
        //console.log("mounted point attendees:", JSON.stringify(this.form.attendees, null, 2));
    },

    computed:{
        attendeesDisplay() {
          if (!Array.isArray(this.form.attendees)) {
            console.warn('attendees 데이터가 배열이 아닙니다:', this.form.attendees);
            return '';
          }
            return Array.isArray(this.form.attendees)
                ? this.form.attendees.map((user) => user.name).join(", ")
                : "";
        },
    },


    methods: {
      convertUtcToKst(utcDateStr) {
        if (!utcDateStr) return '';
        const utcDate = new Date(utcDateStr);
        const kstOffset = 9 * 60 * 60 * 1000; // 9시간(밀리초)
        const kstDate = new Date(utcDate.getTime() + kstOffset);
        return kstDate.toISOString().slice(0, 16); // datetime-local 형식으로 반환
      },
      validateEndDate() {
        if (new Date(this.form.end) <= new Date(this.form.start)) {
          Swal.fire("오류", "종료일시는 시작일시보다 커야 합니다.", "error");
          this.form.end = "";
        }
      },
      validateSmsToggle() {
        if (!this.form.attendees.length) {
          Swal.fire("오류", "참석자 정보를 입력하세요.", "error");
          this.form.sms = false;
        }
      },
      validateSendDateTime() {
        console.log("<============check Validate Send_time===============>");
        if (new Date(this.form.send_time) <= new Date()) {
          Swal.fire("오류", "발송일시는 현재 시간 이후여야 합니다.", "error");
          //this.form.send_time = "";
        }
      },
      
      toggleBatchSelection(batch) {
        if (this.selectedBatches === batch) {
            // 🔹 이미 선택된 기수를 다시 클릭하면 해제
            this.selectedBatches = null;
            this.form.batch = null;
            this.form.attendees = [];
        } else {
            // 🔹 새로운 기수를 선택하면 해당 기수의 참석자 목록을 설정
            this.selectedBatches = batch;
            this.form.batch = batch;
            this.form.attendees = this.groupedByBatchSample[batch] || [];
        }
      },
      closeBatchModal() {
        this.showBatchModal = false;
      },
      setBackgroundColor(color) {
        this.form.backgroundColor = color;
      },
      deleteEvent() {
        Swal.fire({
          title: "삭제 확인",
          text: "정말 삭제하시겠습니까?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "삭제",
          cancelButtonText: "취소",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit("delete-event", this.form.id);
            this.closeModal();
          }
        });
        
      },
      openBatchModal() {
        this.showBatchModal = true;
      },
      closeModal() {
        this.$emit("close");
      },
      updateMessageBody() {
        
        const selectedTemplate = this.messages.find(
          (template) => template.MSG_UUID === this.form.templateID
        );
        if (selectedTemplate) {
          this.form.msg_body = selectedTemplate.MSG_CONTENT || ''; // 템플릿 내용 설정
        } else {
          this.form.msg_body = ''; // 템플릿이 없으면 초기화
        }
        
        console.log('Messages in updateMessageBody:', this.messages);
        console.log('TemplateID in updateMessageBody:', this.form.templateID);
        console.log("EventModal.msg_body: "+this.form.msg_body);
      },
      async fetchBatchData() {
        const tokenData = JSON.parse(sessionStorage.getItem('token'));
        const token = tokenData ? tokenData.access_token : '';

        const config = {
          method: 'get',
          url: '/users/batch-list',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };

        try {
          const response = await axios(config);
          this.groupedByBatchSample = response.data.reduce((acc, batch) => {
            acc[batch.batch] = batch.users;
            return acc;
          }, {});
          //console.log('Fetched Batch List:', this.groupedByBatchSample);
        } catch (error) {
          console.error('Error fetching batch list:', error);
          Swal.fire('Error', 'Failed to fetch batch list', 'error');
        }
      },  

      async fetchTemplate() {
        const tokenData = JSON.parse(sessionStorage.getItem('token'));
        const token = tokenData ? tokenData.access_token : '';

        const config = {
          method: 'get',
          url: `/sms-template/read_formed_messages`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
        try {
          const response = await axios(config);
          this.messages = response.data
            .map((item) => ({
              MSG_UUID: item.uuid,
              MSG_ID: item.msg_id,
              MSG_NAME: item.msg_name,
              MSG_CONTENT: item.msg_contents,
            }))
            .sort((a, b) => a.MSG_ID - b.MSG_ID);
            console.log("fetchTemplate.message"+this.messages);
        } catch (error) {
          console.error('메시지 가져오기 오류:', error);
        }
      },

      submitForm() {
          console.log('Form submitted:', this.form);

          const formattedData = {
              id: this.form.id,
              subject: this.form.title || '', // 제목
              start_time: this.form.start, 
              end_time: this.form.end,
              contents: this.form.content || '', // 일정 내용
              sendYN: this.form.sms || false, // SMS 발송 여부
              templateID: this.form.templateID || '', // 템플릿 ID
              send_time: this.form.send_time, // 발송 시간 ISO 형식 변환
              backgroundColor: this.form.backgroundColor || '#2396f2', // 배경색
              attendees: this.form.attendees || [],
              msg_body: this.form.msg_body || '',
              batch: this.form.batch ||'',
          };

          console.log('Formatted Data for update:', formattedData);
          
          this.$emit('update-event', formattedData); // 부모 컴포넌트(calendar.vue)로 이벤트 전달
          this.closeModal();
      },

    },
  };
  </script>
  
  
 
 <style scoped>

.color-buttons {
  display: flex;
  gap: 1px;
  align-items: center; /* 버튼과 미리보기를 같은 높이로 정렬 */
  flex-wrap: nowrap; /* 버튼을 한 줄로 표시 */
  margin-bottom: 10px;
}

.color-btn {
  width: 30px; /* 버튼의 가로 크기 */
  height: 30px; /* 버튼의 세로 크기 */
  border: none; /* 기본 테두리 제거 */
  border-radius: 5px; /* 모서리를 둥글게 */
  cursor: pointer; /* 커서 변경 */
  line-height: 1; /* 텍스트 세로 정렬 */
  font-size: 0; /* 글자를 숨김 */
  padding: 0; /* 내부 여백 제거 */
  position: relative; /* 선택된 상태 테두리 위치 조정 */
  border: 1px solid #ccc; /* 기본 테두리 */
}

.color-btn:hover {
  opacity: 0.8; /* 호버 시 효과 */
}

.color-btn:focus {
  outline: none; /* 클릭 시 포커스 스타일 제거 */
}

.color-btn.selected {
  border: 3px solid black; /* 선택된 버튼 테두리 */
}

.color-preview {
  width: 180px; /* 미리보기 가로 크기 */
  height: 30px; /* 미리보기 높이 (버튼과 동일) */
  border: 1px solid #ccc; /* 테두리 */
  margin-left: auto; /* 버튼과 간격 */
  background-color: var(--preview-bg, #ffffff); /* 동적으로 색상 반영 */
  display: flex;
  align-items: center; /* 텍스트 세로 가운데 정렬 */
  justify-content: center; /* 텍스트 가로 가운데 정렬 */
  font-size: 14px; /* 텍스트 크기 */
  color: #ffffff; /* 텍스트 색상 */
}


.send-time {
  display: flex;
  align-items: center;
}

.send-time select {
  margin-right: 5px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  
  padding: 20px 30px;
  border-radius: 10px;
  max-width: 540px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;  
}

label {
  display: block;
  margin-bottom: 1px;
  font-size: 16px;
}

input[type="text"],
input[type="datetime-local"],
textarea,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}



button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="button"] {
  background-color: #6c757d;
}

.delete-button{
  background-color: #ff0000 !important;
}
.sms-toggle {
  margin-top: 10px;
  display: flex; /* 한 줄로 배치 */
  align-items: center; /* 수직 정렬 */
  gap: 20px; /* 라벨과 슬라이더 사이 간격 */
  margin-bottom: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #4caf50;
}
input:checked + .slider:before {
  transform: translateX(20px);
}
.attendees-section {
  display: flex;
  align-items: center; /* 세로 가운데 정렬 */
  gap: 10px; /* 입력창과 버튼 간 간격 */
  height: 40px; /* 섹션 전체 높이를 명시적으로 40px로 설정 */
}

.attendees-section input {
  margin-top: 10px;  
  flex: 1; /* 입력창이 남은 공간을 채우도록 설정 */
  height: 40px; /* 입력창 높이를 버튼과 동일하게 설정 */
  padding: 0 10px; /* 내부 여백 설정 */
  font-size: 14px; /* 텍스트 크기 */
  border: 1px solid #ccc; /* 테두리 */
  border-radius: 5px; /* 모서리를 둥글게 */
  box-sizing: border-box; /* 크기 계산에 테두리와 패딩 포함 */
  
}

.attendees-section button {
  height: 40px; 
  padding: 0 15px; 
  font-size: 14px;
  border: none; 
  border-radius: 5px; 
  background-color: #6c757d; 
  color: white; 
  cursor: pointer; 
  box-sizing: border-box; 
  margin-right: 0;
}


.attendees-section button:hover {
  background-color: #5a6268; 
}

.user-modal-wrapper {

  position: absolute; /* 부모 컨테이너 기준으로 위치 */
  top: 10%; /* 일정등록 모달 안에 위치하도록 조정 */
  left: 50%;
  transform: translate(-50%, 0); /* 가로 정렬 중앙 */
  width: 90%; /* 일정등록 모달 크기에 맞추어 */
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;

}

.user-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-height: 360px; 
  
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.batch-modal-wrapper {
  /* position: absolute;
    top: 40%; 
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1201;  */


    position: absolute; /* 부모 컨테이너 기준으로 위치 */
  top: 15%; /* 일정등록 모달 안에 위치하도록 조정 */
  left: 50%;
  transform: translate(-50%, 0); /* 가로 정렬 중앙 */
  width: 90%; /* 일정등록 모달 크기에 맞추어 */
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
}

.batch-modal-content {
  /* background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 350px;
  max-height: 360px;
  overflow-y: auto; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;  */
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-height: 360px; 
  
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.user-list-wrapper {
  max-height: 360px; /* 사용자 목록 10명 기준 (36px x 10) */
  overflow-y: auto; /* 내부 스크롤 활성화 */
  border: 1px solid #ccc; /* 테두리 추가 */
  border-radius: 5px; /* 테두리 둥글게 */
  padding: 5px; /* 내부 여백 */
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-item {
  padding: 5px 8px; /* 줄 간격 줄이기 */
  font-size: 12px; /* 폰트 크기 12px로 조정 */
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.user-item:hover {
  background-color: #f5f5f5;
}

.close-btn {
  /* margin-top: 50px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer; */

  margin-top: 20px; /* 리스트와 버튼 간격 */
  padding: 8px 16px; /* 버튼 크기 조정 */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* 텍스트 크기 */
  align-self: center; /* 버튼을 중앙으로 정렬 */
}

.close-btn:hover {
  background-color: #0056b3;
}

.batch-list {
  /* list-style: none; 
  padding: 0; 
  margin: 0;  */
  list-style: none; /* 기본 리스트 스타일 제거 */
  padding: 0; /* 기본 padding 제거 */
  margin: 0; /* 기본 margin 제거 */
  border: 1px solid #ccc; /* 테두리 추가 */
  border-radius: 5px; /* 둥근 모서리 */
  max-height: 230px; /* 최대 높이 설정 */
  overflow-y: auto; /* 세로 스크롤 활성화 */
}



.batch-item {
  padding: 5px 8px; /* 줄 간격 줄이기 */
  font-size: 12px; /* 폰트 크기 12px로 조정 */
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.batch-item:hover {
  background-color: #f5f5f5;
}

.batch-item.selected {
  background-color: rgb(110, 113, 189); /* 선택된 항목 배경색 */
  color: white; /* 선택된 항목 글자색 */
  font-weight: bold; /* 선택된 항목 글자 강조 */
  border-radius: 5px; /* 모서리 둥글게 (선택 사항) */
}

.placeholder-text {
  color: #ff0000; /* 텍스트 색상 */
  font-size: 0.8em; /* 크기 조정 */
  margin-bottom: 5px; /* 간격 추가 */
}

</style>   