<template>
  <div class="calendar-container">
    <!--<button @click="newEvent">New event</button>-->
    <Fullcalendar 
      ref="fullCalendar" 
      :plugins="calendarPlugins" 
      :header="{
        left: 'dayGridMonth, timeGridWeek, timeGridDay, listWeek',
        center: 'title',
        right: 'prev today next'
      }"
      :buttonText="{
        today: '오늘',
        month: '월별',
        week: '주별',
        day: '일별',
        list: '리스트'
      }"
      :weekends="true"
      :selectable="true"
      :editable="true"
      :events="events"  
      :locale="'ko'"
      
      :eventRender="customEventRender"      

      @select="handleSelect"
      @eventClick="handleEventClick"
      @eventResize="updateEvent"
      @eventDrop="updateEvent"
      
      
    />

    <modal 
      v-if="showModal" 
      :isVisible="showModal" 
      :event="selectedEvent"
      @close="showModal = false" 
      @add-event="saveEvent"
    />
    <event-modal 
      v-if="showEventModal" 
      :isVisible="showEventModal" 
      :event="selectedEvent" 
      @close="showEventModal = false" 
      @update-event="updateEvent"
      @delete-event="deleteEvent"
    />
    <comments-modal v-if="showCommentsModal" @close="showCommentsModal = false" />
  </div>
</template>

<script>
import Fullcalendar from '@fullcalendar/vue'
import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import InteractionPlugin from '@fullcalendar/interaction'
import ListPlugin from '@fullcalendar/list'
//import { mapGetters } from 'vuex'
import Modal from './Modal'
import EventModal from './EventModal'
// import CommentsModal from './CommentsModal'
import Swal from 'sweetalert2';
import axios from "@/axios";

export default {
  data: () => ({
    calendarPlugins: [
      DayGridPlugin,
      TimeGridPlugin,
      InteractionPlugin,
      ListPlugin
    ],
    showModal: false,
    showEventModal: false,
     showCommentsModal: false,
    
    selectedEvent: {
      id: null,
      title: '',
      start: '',
      backgroundColor: '#2396f2',
      textColor: '#ffffff',
    },
    events: [] // 이벤트 배열
  }),
  components: { 
    Fullcalendar, 
    Modal, 
    EventModal, 
    // CommentsModal 
  },
//   watch: {
//   showModal(val) {
//     console.log('ShowModal in Calendar:', val);
//   },
//   selectedEvent(newValue) {
//     console.log('SelectedEvent in Calendar:', newValue);
//   }
// },
  mounted() {
    this.fetchEvents(); // 컴포넌트 로드 시 일정 데이터 가져오기
    console.log('Events:', this.events); // 이벤트 데이터 확인
  },
  methods: {
    customEventRender(info) {
      // 이벤트 커스터마이징 (배경색, 글자색, 스타일 설정)
      const { el, event } = info;
      // console.log("Rendering Event:", event); // 이벤트 정보 확인
      // console.log("Event Element:", el);   

      if (event.backgroundColor) {
        el.style.backgroundColor = event.backgroundColor;
        // console.log("Background color applied:", event.extendedProps.backgroundColor);
      }
      if (event.textColor) {
        el.style.color = event.textColor;
        // console.log("Text color applied:", event.extendedProps.textColor);
      }
      el.style.color = '#ffffff';     
      el.style.textAlign = 'center'; // 텍스트 정렬

      // 텍스트 정렬
        el.style.textAlign = 'center';

      // SMS 상태에 따른 아이콘 추가
      const { sms } = event.extendedProps; // extendedProps에서 SMS 상태 확인
      if (sms) {
        const icon = document.createElement('i');
        icon.className = 'fas fa-envelope'; // Font Awesome 아이콘 클래스
        icon.style.marginLeft = '5px'; // 아이콘과 텍스트 간격
        icon.style.color = '#ffffff'; // 아이콘 색상
        //el.querySelector('.fc-title').appendChild(icon); // 이벤트 제목 뒤에 추가
        const titleElement = el.querySelector('.fc-title');
        if (titleElement) {
          titleElement.appendChild(icon);
        }
      }
    },
   
     
    // newEvent() {
    //   this.selectedEvent = {
    //     id: null,
    //     title: '',
    //     start: '',
    //     backgroundColor: '#2396f2',
    //     textColor: '#ffffff',
    //   };
    //   this.showModal = true;
    // },
    async fetchEvents() {
      // 로컬 스토리지에서 토큰 가져오기
      const tokenData = JSON.parse(sessionStorage.getItem('token'));
      const token = tokenData ? tokenData.access_token : ''; 

      if (!token) {
        Swal.fire('오류', '인증 토큰이 없습니다. 다시 로그인해주세요.', 'error');
        return;
      }

      const config = {
        method: 'get',
        url: '/calendar', // 백엔드의 calendar 데이터 조회 URL
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // 인증 토큰 추가
        },
      };

      try {
        const response = await axios(config);
        const calendars = response.data; 


       // ✅ attendee 조회 대신 batch 기반으로 userv2에서 사용자 조회
        const batchList = [...new Set(calendars.map(c => c.batch).filter(b => b))]; // 중복 제거
        const attendeesByBatch = {};

        if (batchList.length > 0) {
          const batchRequests = batchList.map(batch =>
            axios.get(`/calendar/batch-attendees/${batch}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          );

          const batchResponses = await Promise.all(batchRequests);
          batchResponses.forEach((res, idx) => {
            attendeesByBatch[batchList[idx]] = res.data; // 해당 batch의 참석자 리스트 저장
          });
        }

        // 템플릿 메시지 데이터를 가져오기 위한 요청
        const templateConfig = {
          method: 'get',
          url: `/sms-template/read_formed_messages`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
        const templateResponse = await axios(templateConfig);
        const templates = templateResponse.data;


        this.events = calendars.map((calendar) => {
          const calendarAttendees = attendeesByBatch[calendar.batch] || []; // 해당 batch의 참석자 데이터
          const matchedTemplate = templates.find(template => template.uuid === calendar.templateID);

            
        // 백엔드에서 받은 데이터를 FullCalendar 이벤트 형식으로 매핑
            return {
            id: calendar.uuid, // 일정 ID
            title: calendar.subject, // 일정 제목
            start: calendar.start_time, // 시작 시간
            end: calendar.end_time, // 종료 시간
            backgroundColor: calendar.backgroundColor || '#2396f2',
            extendedProps: {
              content: calendar.contents || '', // 내용
              sms: calendar.sendYN || false, // SMS 여부
              templateID: calendar.templateID || '', // 템플릿 ID
              sendTime: calendar.send_time || '', // 발송 시간
              attendees: calendarAttendees, // 참석자 데이터
              msg_body: matchedTemplate ? matchedTemplate.msg_contents : '', // 템플릿 메시지 본문
              adminUUID: calendar.admin_UUID || '', // 관리자 UUID
              batch: calendar.batch ||'',
               // 배경색
            },
          };
        });
        console.log('Mapped events:', this.events); // 디버깅용
       this.$refs.fullCalendar.getApi().refetchEvents(); 
        //this.$refs.fullCalendar.getApi().removeAllEvents(); // 기존 이벤트 제거
        //this.$refs.fullCalendar.getApi().addEventSource(this.events); // ✅ 이벤트 다시 추가
      } catch (error) {
        Swal.fire('오류', '일정 조회 중 오류가 발생했습니다.', 'error');
        console.error('일정 조회 오류:', error);
      }
    },

    async saveEvent(eventData) {
      // 로컬 스토리지에서 토큰 가져오기
      const tokenData = JSON.parse(sessionStorage.getItem('token'));
      const token = tokenData ? tokenData.access_token : '';
      //console.log("saveEvnet called!!!");
      if (!token) {
        Swal.fire('오류', '인증 토큰이 없습니다. 다시 로그인해주세요.', 'error');
        return;
      }
      // 참석자 데이터 확인 로그
        console.log("Attendees before save:", eventData.attendees);

      // 참석자 데이터 검증
      if (eventData.attendees.some(attendee => !attendee.userid || !attendee.name || !attendee.phone)) {
        Swal.fire('오류', '참석자 데이터 형식이 올바르지 않습니다.', 'error');
        return;
      }
      const config = {
        method: 'post',
        url: '/calendar', // 백엔드의 일정 저장 URL
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // 인증 토큰 추가
        },
        data: {
          ...eventData,
          attendees: undefined,
          msg_body: undefined,
          batch:eventData.batch,
        }
      };

      try 
      {
        const response = await axios(config);
        const calendarId = response.data.uuid; //생성된 일정 ID 가져오기

       // 참석자 저장
          // if (eventData.attendees && eventData.attendees.length > 0) {
          //   //console.log("eventData.attendees2"+eventData.attendees);
          //   await Promise.all(
          //     eventData.attendees.map(attendee =>
          //       axios.post('/calendar/attendee/create', {
          //         calendar_ID: calendarId,
          //         userid: attendee.userid,
          //         name: attendee.name,
          //         phone: attendee.phone,
          //       }, {
          //         headers: { Authorization: `Bearer ${token}` },
          //       })
          //     )
          //   );
          // }

          let smsSuccess = false;
          console.log("eventData.sendYN: "+eventData.sendYN);
          if(eventData.sendYN){
              const smsData = eventData.attendees.map(attendee => ({
              
              cmid: `${Date.now()}`, // CMID 생성
              calendar_ID: calendarId,
              msg_type: 0, // SMS
              status: 0, // 대기 상태
              request_time: new Date().toISOString(),
              send_time: new Date(eventData.send_time).toISOString(), 
              dest_phone: attendee.phone,
              send_phone: process.env.VUE_APP_SENDER_NUMBER, // Vuex store의 발신자 번호
              msg_body: eventData.msg_body.includes('${name}')
                        ? eventData.msg_body.replace('${name}', attendee.name.substring(0, 3)) // ${name}이 있을 경우 치환
                        : eventData.msg_body, // ${name}이 없을 경우 원본 메시지 사용
            }));
          
            // SMS 전송
            smsSuccess = await this.sendSms(smsData);

          }     
          console.log("Before events.push:", eventData.msg_body);
          // 저장된 이벤트를 FullCalendar 이벤트 형식으로 추가
          this.events.push({
            id: response.data.uuid,
            title: response.data.subject,
            start: response.data.start_time,
            end: response.data.end_time,
            backgroundColor: response.data.backgroundColor,
            extendedProps: {
              content: response.data.contents,
              sms: smsSuccess,
              template: response.data.templateID,
              sendTime: response.data.send_time,
              attendees: eventData.attendees || [],
              msg_body: eventData.msg_body ?? "",
              batch: eventData.batch, 
            },
          });
          console.log("After events.push:", this.events[this.events.length - 1].extendedProps.msg_body);
          await this.fetchEvents(); // 이걸 추가해서 즉시 반영되도록 한다!

         //Swal.fire('성공', '일정이 성공적으로 등록되었습니다.', 'success');
      } catch (error) {
        Swal.fire('오류', '일정 저장 중 오류가 발생했습니다.', 'error');
        console.error('일정 저장 오류:', error);
      }
    },

    async sendSms(smsData) {
      const token = JSON.parse(sessionStorage.getItem('token'))?.access_token;
      try {
        // SMS 데이터 일괄 전송
        await Promise.all(
          smsData.map(data =>
            axios.post('/sms-main/create_message?cmidPrefix=C', data, {
              headers: { Authorization: `Bearer ${token}` },
            })
          )
        );
        return true;
      } catch (error) {
        console.error('SMS 등록 오류:', error);
        Swal.fire('오류', 'SMS 등록 중 오류가 발생했습니다.', 'error');
      }
    },


   async updateEvent(eventData) {
        console.log('=========================');
        console.log('🔵 Event Data:', eventData);

        const token = JSON.parse(sessionStorage.getItem('token'))?.access_token;

        if (!token) {
            Swal.fire('오류', '인증 토큰이 없습니다. 다시 로그인해주세요.', 'error');
            return;
        }

        const { id, attendees, msg_body, ...eventDataWithoutAttendees } = eventData;
     

        try {
            // **🟢 Step 1: 기존 SMS 삭제**
            console.log("📌 Checking existing SMS data for deletion...");
            const smsResponse = await axios.get(`/sms-main/find_by_calendar/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (smsResponse.data.length > 0) {
                console.log("🗑️ Deleting all existing SMS messages linked to calendar_ID...");
                await axios.delete(`/sms-main/delete_messages/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log("✅ Successfully deleted existing SMS messages.");
            }

            // **🟡 Step 2: 새 SMS 데이터 생성 (sendYN이 true일 때만)**
            if (eventData.sendYN) {
                console.log("📌 Creating new SMS messages...");

                const newSmsData = attendees.map(attendee => ({
                    calendar_ID: id,
                    msg_type: 0,  
                    status: 0,    
                    request_time: new Date().toISOString(),  // ✅ ISO 8601 형식
                    send_time: new Date(eventData.send_time).toISOString(),  // ✅ ISO 8601 형식
                    dest_phone: attendee.phone, 
                    send_phone: process.env.VUE_APP_SENDER_NUMBER,  
                    msg_body: msg_body.includes('${name}')
                        ? msg_body.replace('${name}', attendee.name.substring(0, 3))  // ✅ 치환
                        : msg_body,  
                }));

                console.log("✅ New SMS Data to be sent:", JSON.stringify(newSmsData, null, 2));
                
                let smsCreate =false;
                const currentDateTime = new Date();
                for (const smsData of newSmsData) {
                  const sendTime = new Date(smsData.send_time);
                  if (sendTime > currentDateTime) {
                    await axios.post(`/sms-main/create_message?cmidPrefix=C`, smsData, {
                        headers: { Authorization: `Bearer ${token}` },
                        
                    });
                    smsCreate =true;
                  }
                  
                }
                if(smsCreate===false)
                  console.warn(`❌ SMS messages is in the past. Skipping...`);
                else
                  console.log("📌 SMS messages successfully created.");
            } 

            // **🟣 Step 3: 일정 업데이트 (SMS가 정상적으로 생성된 후)**
            console.log("📌 Updating Calendar Event...");
            const cleanEventData = Object.fromEntries(
                Object.entries(eventDataWithoutAttendees).filter(([, value]) => value !== undefined && value !== null && value !== "")
            );

            if (eventData.batch === "" || eventData.batch === null || eventData.batch === undefined) {
                cleanEventData.batch = null;  // ✅ batch 삭제 반영
            } else {
                cleanEventData.batch = eventData.batch;  // ✅ 참석자 대신 batch 정보를 전송
            }

            // 🔹 send_time이 빈 문자열이면 null로 설정
            if (cleanEventData.send_time === "") {
                cleanEventData.send_time = null;
            }

           
            await axios.put(`/calendar/${id}`, cleanEventData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            console.log("✅ Calendar update successful!");

            // **🟢 Step 4: UI 업데이트**
            await this.fetchEvents();  // 캘린더 리로드
            //Swal.fire('✅ 성공', '일정 및 SMS가 업데이트되었습니다.', 'success');
            this.showEventModal = false;  // 모달 닫기
        } catch (error) {
            console.error('❌ Update error:', error.response?.data || error.message);
            Swal.fire('❌ 오류', '서버 업데이트 중 오류가 발생했습니다.', 'error');
        }

        this.isModalVisible = false;  // 모달 닫기
    },

    async deleteEvent(eventId) {
    const tokenData = JSON.parse(sessionStorage.getItem('token'));
    const token = tokenData ? tokenData.access_token : '';

    console.log("delete event created!!!");
    if (!token) {
        Swal.fire('오류', '인증 토큰이 없습니다. 다시 로그인해주세요.', 'error');
        return;
    }

    try {
        // **🔹 Step 1: Check for existing SMS records**
        console.log("📌 Checking if SMS messages exist for this event...");
        const smsResponse = await axios.get(`/sms-main/find_by_calendar/${eventId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        console.log("✅ Existing SMS Data:", smsResponse.data);

        // **🗑️ Step 2: Delete SMS messages if they exist**
        if (smsResponse.data.length > 0) {
            console.log("📌 Deleting related SMS messages...");
            await axios.delete(`/sms-main/delete_messages/${eventId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            console.log("✅ SMS messages successfully deleted.");
        } else {
            console.log("ℹ No SMS messages found, skipping SMS deletion.");
        }

        // **🟢 Step 3: Proceed with event deletion**
        console.log("📌 Deleting calendar event...");
        const config = {
            method: 'delete',
            url: `/calendar/${eventId}`, // uuid를 URL로 전달
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios(config);
        console.log('✅ Delete success:', response.data);

        // **🔄 Step 4: Refresh events after deletion**
        await this.fetchEvents();
        //Swal.fire('✅ 성공', '일정 및 관련 SMS가 성공적으로 삭제되었습니다.', 'success');

    } catch (error) {
        console.error('❌ Delete error:', error.response?.data || error.message);
        Swal.fire('❌ 오류', '일정 삭제 중 오류가 발생했습니다.', 'error');
    }

    this.isModalVisible = false;
},



    // async deleteEvent(eventId) {
    //   const tokenData = JSON.parse(sessionStorage.getItem('token'));
    //   const token = tokenData ? tokenData.access_token : '';
      
    //   console.log("delete event created!!!");
    //   if (!token) {
    //     Swal.fire('오류', '인증 토큰이 없습니다. 다시 로그인해주세요.', 'error');
    //     return;
    //   }

    //   const config = {
    //     method: 'delete',
    //     url: `/calendar/${eventId}`, // uuid를 URL로 전달
    //     headers: {
    //       Authorization: `Bearer ${token}`, // 인증 토큰 추가
    //     },
    //   };

    //   try {
    //     const response = await axios(config);
    //     console.log('Delete success:', response.data);

    //     // 삭제 성공 시 이벤트 목록 갱신
    //     await this.fetchEvents();
    //     Swal.fire('성공', '일정이 성공적으로 삭제되었습니다.', 'success');
    //   } catch (error) {
    //     console.error('Delete error:', error.response?.data || error.message);
    //     Swal.fire('오류', '일정 삭제 중 오류가 발생했습니다.', 'error');
    //   }
    //   this.isModalVisible = false;
    // },

    handleSelect(arg) {
      // console.log('Date selected:', arg); // 이벤트 로그 추가
      // console.log('Start date:', arg.start); // 시작 날짜 확인
      // console.log('End date:', arg.end); // 종료 날짜 확인
      
      this.selectedDate = {
        start: arg.start,
        end: arg.end
      };
      this.selectedEvent = {
        id: null,
        title: '',
        start: arg.start,
        end: arg.end,
        backgroundColor: '#28a745',
        textColor: '#ffffff',
      };
      // console.log('Selected Event in Calendar:', this.selectedEvent); // 로그로 확인
      this.showModal = true;
    },
    handleEventClick(arg) {
      // console.log('Event clicked:', arg.event); // 클릭한 이벤트 데이터 확인
      // console.log('event.start:', arg.event.start); // Date 객체 확인
      // console.log('event.startStr:', arg.event.startStr); // 문자열 형식 확인
      this.selectedEvent = {
        id: arg.event.id,
        title: arg.event.title,
        startStr: arg.event.start ? arg.event.start.toISOString() : '', // ISO 형식으로 변환
        endStr: arg.event.end ? arg.event.end.toISOString() : '', // ISO 형식으로 변환
        backgroundColor: arg.event.backgroundColor,
        extendedProps: {
          content: arg.event.extendedProps?.content || '',
          sms: arg.event.extendedProps?.sms || false,
          templateID: arg.event.extendedProps?.templateID || '',          
          sendTime: arg.event.extendedProps?.sendTime || '',          
          attendees: arg.event.extendedProps?.attendees || [], // 참석자 정보 추가
          adminUUID: arg.event.extendedProps?.adminUUID || '', // 관리자 UUID 추가
          msg_body: arg.event.extendedProps?.msg_body || '', // SMS 메시지 내용 추가   
          batch:arg.event.extendedProps?.batch ||'',     
        }
      };
      //console.log('Selected Event:', this.selectedEvent); // 확인용 로그
      this.showEventModal = true;
    },
   
  }
}
</script>
<style scoped>
.calendar-container {
  padding: 2% 10%; /* 좌우 여백 10% 추가 */
  box-sizing: border-box; /* 패딩이 전체 폭에 포함되도록 설정 */
}
.fc-event { 
  color: #ffffff !important; /* 무조건 흰색 글자색 */
  border: none !important; /* 테두리 제거 */
  border-radius: 5px !important;
  text-align: center;
  font-size: 16px;  
}


</style>


