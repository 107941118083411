<template>
  <div v-if="isVisible" class="modal" >
    <div class="modal-content" >
      <h3>일정등록</h3>
      <form @submit.prevent="submitForm">
        <label for="title">제목</label>
        <input v-model="form.title" type="text" id="title" />
        
        <label for="start">시작일시</label>
        <input v-model="form.start" type="datetime-local" id="start" />
        
        <label for="end">종료일시</label>
        <input v-model="form.end" type="datetime-local" id="end" @blur="validateEndDate"  />

            <!-- 배경색 선택 -->
        <label>배경색상 선택 </label>
          <div class="color-buttons">
            <button
              v-for="(color, index) in colorOptions"
              :key="index"
              :class="{ selected: form.backgroundColor === color.value }"
              :style="{ backgroundColor: color.value }"
              class="color-btn"
              @click.prevent="setBackgroundColor(color.value)"
            ></button>
            
              <div
              class="color-preview"
              :style="{ backgroundColor: form.backgroundColor || '#2396f2', color: '#ffffff' }"
            > 선텍 미리보기           
            </div>
          </div>
         
        
          <label for="content">내용</label>
          <textarea v-model="form.content" id="content"></textarea>
          
          <label for="attendees">참석자</label>
          <div class="attendees-section">
          <input 
           
            v-model="attendeesDisplay"
            type="text" 
            id="attendees" 
            placeholder="참석자 입력"            
            readonly
            
          />
          <!-- <button type="button" @click="openUserModal">사용자별</button> -->
          <button type="button" @click="openBatchModal" class="batch-btn">기수별</button>
        </div>
          
        <!-- 사용자별 선택 모달 -->
        <div v-if="showUserModal" class="user-modal-wrapper">
          <div class="user-modal-content">
            <h3 class="modal-title">사용자 선택/해제</h3>
            <div class="user-list-wrapper">
              <ul class="user-list">
                <li
                  v-for="user in users"
                  :key="user.uuid"
                  class="user-item"
                  @click="toggleUserSelection(user)"
                >
                  <span>{{ user.name }} - {{ user.phone }}</span>
                </li>
              </ul>
            </div>
            <button class="close-btn" @click="closeUserModal">닫기</button>
          </div>
        </div>

        <!-- 기수별 선택 모달 -->
        <div v-if="showBatchModal" class="batch-modal-wrapper">
          <div class="batch-modal-content">
            <h3 class="modal-title">기수별 사용자 선택/해제</h3>
            <ul class="batch-list">
              <li v-for="(group, batch) in groupedByBatchSample" 
              :key="batch"
              class="batch-item"          
              :class="{ selected: selectedBatches === batch }"
              @click="toggleBatchSelection(batch)"
              >
                <span> {{ batch }} </span>
              </li>
            </ul>
            <button class="close-btn" @click="closeBatchModal">닫기</button>
          </div>
        </div>
          <!-- <label for="sms">
            SMS발송여부:
            <input type="checkbox" id="sms" v-model="form.sms" @change="toggleSmsFields" style="margin-left: 10px;" />
          </label> -->
          <div class="sms-toggle">
            <label>SMS 발송 여부</label>
            <label class="switch">
              <input type="checkbox" v-model="form.sms" @change="validateSmsToggle" />
              <span class="slider"></span>
            </label>
          </div> 
          <div v-if="form.sms" >
           
            <div>
              <label for="sms-template">발송문자 템플릿</label>
              <p v-if="!form.attendees || form.attendees.length === 0" class="placeholder-text">
                참석자 정보를 먼저 선택하세요
              </p>
              <select id="sms-template" v-model="form.selectedTemplateUUID" 
               :disabled="!form.attendees || form.attendees.length === 0"  
               @change="updateMessageBody"
               >
                <option value="" disabled selected>
                  템플릿을 선택하세요
                </option>
                <option v-for="template in messages" :key="template.MSG_UUID" :value="template.MSG_UUID">
                  {{ template.MSG_CONTENT }}
                </option>
              </select>
             
            </div>
            
            <div>
              <label for="sendDateTime">발송일시</label>
              <input 
                id="sendDateTime" 
                type="datetime-local" 
                v-model="form.sendDateTime" 
                :disabled="!form.selectedTemplateUUID || !form.attendees.length"
                 @change="validateSendDateTime"
              />
            </div>
           
          </div>
           <!-- 버튼 -->
          <div class="modal-buttons">
            <button type="submit">저장</button>
            <button type="button" @click="closeModal">닫기</button>
        </div>
      </form>
      <slot></slot>
    </div>
  </div>
</template>


<script>
import axios from "@/axios";
import Swal from 'sweetalert2';

export default {
  // props: ['isVisible', 'event'],
  props: {
    isVisible: {
      type: Boolean,
      required: true // 필수 prop
    },
    event: {
      type: Object, // 반드시 객체여야 함
      required: false // 필수가 아님
    }
  },
  data() {
    return {
      form: {
        title: '',
        start: this.event ? this.event.startStr : '', // props로 받은 시작일시
        end: this.event ? this.event.endStr : '',     // props로 받은 종료일시
        content: '',
        sms: false,
        templateID: '',
        sendDateTime: "",
        batch:'',
        attendees: [],
        backgroundColor: '#2396f2', 
        selectedTemplateUUID: null,
        msg_body: '', 
      },
      colorOptions: [
        { name: '초록색', value: '#59b95a' },
        { name: '파란색', value: '#2396f2' },
        { name: '빨간색', value: '#dc3545' },
        { name: '노란색', value: '#ffc107' },
        { name: '보라색', value: '#6f42c1' },
        { name: '주황색', value: '#fd5722' },
        { name: '갈색', value: '#765743' },
      ],
      messages: [], // 템플릿 데이터 저장     
      users: [], // 전체 사용자 목록
      userType: 'approved',
      selectedAttendees: "", // 선택된 사용자 이름 목록
      selectedUsers: [], // 선택된 사용자 UUID 목록
      
      showUserModal: false, // 사용자별 모달 표시
      showBatchModal: false, // 기수별 모달 표시
      groupedByBatchSample: [],
      selectedBatches: "", // 선택된 기수 목록 
      
    }
  },
  
  watch: {
    
    //날짜 클릭시 해당 날짜를 가져와 시작일시에 표시 로직
    event: {
      handler(newEvent) {
        //console.log('Watch detected event change:', newEvent);
        const toLocalDatetime = (utcDate) => {
          const localDate = new Date(utcDate);
          localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
          return localDate.toISOString().slice(0, 16); // yyyy-MM-ddTHH:mm 형식
        };
        if (newEvent) {
          this.form = {
            title: newEvent.title || '',
            start: newEvent.start ? toLocalDatetime(newEvent.start) : '',
            end: '',
            content: newEvent.extendedProps?.content || '',
            sms: newEvent.extendedProps?.sms || false,
            template: newEvent.extendedProps?.template || '',
            attendees: newEvent.extendedProps?.attendees || [],
            backgroundColor: newEvent.extendedProps?.backgroundColor || '#2396f2',
          };
          // 필요 시 추가 로직
        }
      },
      deep: true, // 객체 내부 변화 감지
      immediate: true // 컴포넌트 초기화 시 즉시 실행
    },
  // isVisible(val) {
    
  //   if (val) {
  //     // 모달이 열릴 때 사용자 데이터 가져오기
  //     this.fetchUsers();
      
  //     if (this.event) {
  //       // 이벤트 데이터가 존재하면 폼 데이터 초기화
  //       console.log('Event passed to Modal:', this.event); // 이벤트 데이터 확인
  //       this.form = {
  //         title: this.event.title || '',
  //         start: this.event.startStr || '',
  //         end: this.event.endStr || '',
  //         content: this.event.extendedProps.content || '',
  //         sms: this.event.extendedProps.sms || false,
  //         template: this.event.extendedProps.template || '',
  //         attendees: this.event.extendedProps.attendees || '',
  //         backgroundColor: this.event.extendedProps.backgroundColor || '#2396f2',
  //       };
  //     }
  //   }
  // },
},
  mounted(){
   
    this.fetchTemplate();
    this.fetchUsers();
    this.fetchBatchData();
  },
  computed: {
    attendeesDisplay() {
      return this.form.attendees.map((user) => user.name).join(', ');
    },
    groupedByBatch() {
      // 기수별 그룹핑
      return this.users.reduce((groups, user) => {
        const batch = user.batch || "기타";
        if (!groups[batch]) groups[batch] = [];
        groups[batch].push(user);
        return groups;
      }, {});
    },
  },
  methods: {
    validateEndDate() {
      //console.log("validateEndDate 호출!!!");
      const startDateTime = new Date(this.form.start);
      const endDateTime = new Date(this.form.end);
      //console.log("시작일시:"+startDateTime.getTime());
      //console.log("종료일시:"+endDateTime.getTime());
      if (endDateTime.getTime() <= startDateTime.getTime()) {
        this.form.end = ''; // 종료일시 초기화
        Swal.fire({
          title: '오류',
          text: '종료일시는 시작일시보다 나중이어야 합니다.',
          icon: 'error',
        });
      }
    },
    
    validateSendDateTime() {
      if (!this.form.sendDateTime) {
        return; // 발송일시가 선택되지 않았으면 검증할 필요 없음
      }

      const selectedDateTime = new Date(this.form.sendDateTime); // 선택된 발송일시
      const currentDateTime = new Date(); // 현재 일시

      if (selectedDateTime < currentDateTime) {
        Swal.fire('오류', '발송일시는 현재일시보다 이전일 수 없습니다!', 'error');
        this.form.sendDateTime = ''; // 발송일시 초기화
      } else {
        console.log('발송일시 선택됨:', this.form.sendDateTime);
      }
    },

    validateSmsToggle(event){
      if (!this.form.attendees || this.form.attendees.length === 0) {
        Swal.fire('오류', '참석자정보 선행입력 필요!', 'error');
        this.form.sms = false; // 상태를 강제로 꺼줌
        event.target.checked = false; // DOM 업데이트
      }
    },
    updateMessageBody() {
      if (!this.form.attendees || this.form.attendees.length === 0) {
        Swal.fire('오류', '참석자정보 선행입력 필요!', 'error');
        this.form.selectedTemplateUUID = null; // 선택 초기화
      }
      // 선택된 템플릿 UUID로 메시지 내용 설정
      const selectedTemplate = this.messages.find(
        template => template.MSG_UUID === this.form.selectedTemplateUUID
      );
      if (selectedTemplate) {
        this.form.msg_body = selectedTemplate.MSG_CONTENT || ''; // 메시지 내용 설정
        this.form.templateID = selectedTemplate.MSG_UUID; // templateID 설정
        console.log("templateID in it <====!!!!!");
      } else {
        this.form.msg_body = ''; // 템플릿이 선택되지 않은 경우 초기화
        this.for
      }
    },

   
 
    toggleBatchSelection(batch) {
      const selectedBatchUsers = this.groupedByBatchSample[batch].map(
        user=>({userid: user.uuid, name: user.name, phone: user.phone})
      );


       // 이전 선택된 기수를 초기화
      if (this.selectedBatches === batch) {
        this.selectedBatches = ""; // 동일한 기수를 선택하면 해제
        this.form.attendees = []; // 참석자 초기화
        this.form.batch = ""; // batch도 초기화
      } else {
        this.selectedBatches = batch; // 새 기수로 교체
        this.form.attendees = [...selectedBatchUsers]; // 새 기수의 사용자로 참석자 업데이트
        this.form.batch = batch; // batch 필드 업데이트
      }


      //********복수개의 기수 선택시  *************************/
      // // 현재 선택된 참석자 목록 중, 이미 있는 사용자 제거
      // selectedBatchUsers.forEach(user => {
      //   const index = this.form.attendees.findIndex(attendee => attendee.uuid === user.uuid);

      //   if (index !== -1) {
      //     // 이미 있는 사용자라면 제거
      //     this.form.attendees.splice(index, 1);
      //   } else {
      //     // 없는 사용자라면 추가
      //     this.form.attendees.push(user);
      //   }
      // });
      
      //   // 선택된 기수를 관리 (선택 여부에 따라 추가/제거)
      // const batchIndex = this.selectedBatches.indexOf(batch);
      // if (batchIndex !== -1) {
      //   this.selectedBatches.splice(batchIndex, 1); // 이미 선택된 기수라면 제거
      // } else {
      //   this.selectedBatches.push(batch); // 선택된 기수 추가
      // }
      
    },

    async fetchBatchData() {
      const tokenData = JSON.parse(sessionStorage.getItem('token'));
      const token = tokenData ? tokenData.access_token : '';

      const config = {
        method: 'get',
        url: '/users/batch-list',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios(config);
        this.groupedByBatchSample = response.data.reduce((acc, batch) => {
          acc[batch.batch] = batch.users;
          return acc;
        }, {});
        //console.log('Fetched Batch List:', this.groupedByBatchSample);
      } catch (error) {
        console.error('Error fetching batch list:', error);
        Swal.fire('Error', 'Failed to fetch batch list', 'error');
      }
    },  

    async fetchUsers() {
    // 토큰 가져오기
    const tokenData = JSON.parse(sessionStorage.getItem('token'));
    const token = tokenData ? tokenData.access_token : '';

    // 요청 데이터 구성
    const params = {
      status: this.userType , // 사용자 유형 필터 (기본값: 'all')
    };

    // 요청 설정
    const config = {
      method: 'get',
      url: '/users',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` // 인증 토큰
      },
      params, // 쿼리 파라미터 추가
    };

    try {
      // API 요청
      const response = await axios(config);

      // 사용자 데이터 확인 및 처리
      if (response.data && response.data.length > 0) {
        let orderNo = 0;
        this.users = response.data.map((user) => {
          orderNo++;
          return {
            ...user,
            orderNo, // 순번 추가
          };
        });
        //console.log('사용자 목록:', this.users);
      } else {
        //console.log('사용자 데이터가 없습니다.');
        this.users = [];
      }
    } catch (error) {
      console.error('사용자 데이터 가져오기 오류:', error);
      Swal.fire('오류', '사용자 데이터를 가져오는 중 문제가 발생했습니다.', 'error');
    }
  },
  
  toggleUserSelection(user) {
     // 이미 선택된 사용자인지 확인
  const isSelected = this.selectedUsers.some(
    (selectedUser) => selectedUser.userid === user.uuid
  );

  if (isSelected) {
    // 이미 선택된 사용자라면 배열에서 제거
    this.selectedUsers = this.selectedUsers.filter(
      (selectedUser) => selectedUser.userid !== user.uuid
    );
  } else {
    // 선택되지 않은 사용자라면 배열에 추가
    this.selectedUsers.push({
      userid: user.uuid,
      name: user.name,
      phone: user.phone,
    });
  }

  // 참석자 입력창에 표시할 문자열 생성
  this.form.attendees = this.selectedUsers;
  console.log('현재 참석자 배열:', this.form.attendees);
  },
    
   

  // updateSelectedAttendees() {
  //   this.form.attendees = this.users
  //     .filter((user) => this.selectedUsers.includes(user.uuid))
  //     .map((user) => user.name)
  //     .join(', ');
  //   console.log('업데이트된 참석자 목록:', this.form.attendees);
  // },
    isSelected(user) {
      return this.selectedUsers.includes(user.uuid); // 선택된 사용자 배열에서 확인
    },
    openUserModal() {
      this.showUserModal = true;
    },
    closeUserModal() {
      this.showUserModal = false;
    },
    openBatchModal() {
      this.showBatchModal = true;
    },
    closeBatchModal() {
      this.showBatchModal = false;
    },
  
    closeModal() {
      this.$emit('close');
    },
    
    
    async fetchTemplate() {
    const tokenData = JSON.parse(sessionStorage.getItem('token'));
    const token = tokenData ? tokenData.access_token : '';

    const config = {
      method: 'get',
      url: `/sms-template/read_formed_messages`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(config);
      this.messages = response.data
        .map((item) => ({
          MSG_UUID: item.uuid,
          MSG_ID: item.msg_id,
          MSG_NAME: item.msg_name,
          MSG_CONTENT: item.msg_contents,
        }))
        .sort((a, b) => a.MSG_ID - b.MSG_ID);
    } catch (error) {
      console.error('메시지 가져오기 오류:', error);
    }
  },
  
    setBackgroundColor(color) {
      this.form.backgroundColor = color;
      //console.log("selectd Color: "+color);
    },
    
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
   
   
    submitForm() {
      // if (!Array.isArray(this.form.attendees)) {
      //   console.log('attendees가 배열이 아닙니다. 초기화합니다.');
      //   this.form.attendees = []; // 배열이 아니면 초기화
      // }
      const adminId = sessionStorage.getItem('adminId');

      // 필수 필드 확인
      const fieldLabels = {
        title: '제목',
        start: '시작일시',
        end: '종료일시',
        content: '내용',
        selectedTemplateUUID: '발송문자 템플릿',
        sendDateTime: '발송일시',
        attendees: '참석자',
      };

      const requiredFields = ['title', 'start', 'end', 'content'];
      const missingFields = requiredFields
        .filter(field => !this.form[field])
        .map(field => fieldLabels[field]);

      if (this.form.sms) {
        if (!this.form.selectedTemplateUUID) {
          missingFields.push(fieldLabels.selectedTemplateUUID);
        }
        if (!this.form.sendDateTime) {
          missingFields.push(fieldLabels.sendDateTime);
        }
        if (!Array.isArray(this.form.attendees) || this.form.attendees.length === 0) {
          missingFields.push(fieldLabels.attendees);
        }
      }
  
      // 비어 있는  필드가 있을 경우 SweetAlert2로 경고
      if (missingFields.length > 0) {
        Swal.fire(
          '입력 오류',
          `다음 항목을 채워주세요: ${missingFields.join(', ')}`,
          'error'
        );
        return;
      }
      // 데이터를 백엔드 요구사항에 맞게 변환
      const formattedData = {
        uuid: this.generateUUID(),
        subject: this.form.title,
        start_time: new Date(this.form.start), // ISO 8601로 변환
        end_time: new Date(this.form.end), // ISO 8601로 변환
        contents: this.form.content,
        sendYN: this.form.sms,
        templateID: this.form.templateID || null,
        // send_time: this.form.sms
        //   ? new Date(`${this.form.sendDays}T${this.form.sendHours}:${this.form.sendMinutes}:00`)
        //   : null,
        send_time: this.form.sms && this.form.sendDateTime // SMS가 선택되었을 경우만 처리
          ? new Date(this.form.sendDateTime).toISOString()
          : null,
        admin_UUID: adminId, // 예제 관리자 ID
        backgroundColor: this.form.backgroundColor,
        attendees: this.form.attendees, // attendees 배열 그대로 전달
        msg_body: this.form.msg_body,
        batch: this.form.batch
      };

      console.log('Form submitted:', formattedData);
      this.$emit('add-event', formattedData); // 부모 컴포넌트로 전달
      this.closeModal();
    },
   
  }
}


</script>


<style scoped>

.color-buttons {
  display: flex;
  gap: 1px;
  align-items: center; /* 버튼과 미리보기를 같은 높이로 정렬 */
  flex-wrap: nowrap; /* 버튼을 한 줄로 표시 */
  margin-bottom: 10px;
}

.color-btn {
  width: 30px; /* 버튼의 가로 크기 */
  height: 30px; /* 버튼의 세로 크기 */
  border: none; /* 기본 테두리 제거 */
  border-radius: 5px; /* 모서리를 둥글게 */
  cursor: pointer; /* 커서 변경 */
  line-height: 1; /* 텍스트 세로 정렬 */
  font-size: 0; /* 글자를 숨김 */
  padding: 0; /* 내부 여백 제거 */
  position: relative; /* 선택된 상태 테두리 위치 조정 */
  border: 1px solid #ccc; /* 기본 테두리 */
}

.color-btn:hover {
  opacity: 0.8; /* 호버 시 효과 */
}

.color-btn:focus {
  outline: none; /* 클릭 시 포커스 스타일 제거 */
}

.color-btn.selected {
  border: 3px solid black; /* 선택된 버튼 테두리 */
}

.color-preview {
  width: 180px; /* 미리보기 가로 크기 */
  height: 30px; /* 미리보기 높이 (버튼과 동일) */
  border: 1px solid #ccc; /* 테두리 */
  margin-left: auto; /* 버튼과 간격 */
  background-color: var(--preview-bg, #ffffff); /* 동적으로 색상 반영 */
  display: flex;
  align-items: center; /* 텍스트 세로 가운데 정렬 */
  justify-content: center; /* 텍스트 가로 가운데 정렬 */
  font-size: 14px; /* 텍스트 크기 */
  color: #ffffff; /* 텍스트 색상 */
}


.send-time {
  display: flex;
  align-items: center;
}

.send-time select {
  margin-right: 5px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  
  padding: 20px 30px;
  border-radius: 10px;
  max-width: 540px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;  
}

label {
  display: block;
  margin-bottom: 1px;
  font-size: 16px;
}

input[type="text"],
input[type="datetime-local"],
textarea,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}



button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="button"] {
  background-color: #6c757d;
}


.sms-toggle {
  margin-top: 10px;
  display: flex; /* 한 줄로 배치 */
  align-items: center; /* 수직 정렬 */
  gap: 20px; /* 라벨과 슬라이더 사이 간격 */
  margin-bottom: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #4caf50;
}
input:checked + .slider:before {
  transform: translateX(20px);
}
.attendees-section {
  display: flex;
  align-items: center; /* 세로 가운데 정렬 */
  gap: 10px; /* 입력창과 버튼 간 간격 */
  height: 40px; /* 섹션 전체 높이를 명시적으로 40px로 설정 */
}

.attendees-section input {
  margin-top: 10px;  
  flex: 1; /* 입력창이 남은 공간을 채우도록 설정 */
  height: 40px; /* 입력창 높이를 버튼과 동일하게 설정 */
  padding: 0 10px; /* 내부 여백 설정 */
  font-size: 14px; /* 텍스트 크기 */
  border: 1px solid #ccc; /* 테두리 */
  border-radius: 5px; /* 모서리를 둥글게 */
  box-sizing: border-box; /* 크기 계산에 테두리와 패딩 포함 */
  
}

.attendees-section button {
  height: 40px; 
  padding: 0 15px; 
  font-size: 14px;
  border: none; 
  border-radius: 5px; 
  background-color: #6c757d; 
  color: white; 
  cursor: pointer; 
  box-sizing: border-box; 
  margin-right: 0;
}


.attendees-section button:hover {
  background-color: #5a6268; 
}

.user-modal-wrapper {

  position: absolute; /* 부모 컨테이너 기준으로 위치 */
  top: 10%; /* 일정등록 모달 안에 위치하도록 조정 */
  left: 50%;
  transform: translate(-50%, 0); /* 가로 정렬 중앙 */
  width: 90%; /* 일정등록 모달 크기에 맞추어 */
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;

}

.user-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-height: 360px; 
  
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.batch-modal-wrapper {
  /* position: absolute;
    top: 40%; 
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1201;  */


    position: absolute; /* 부모 컨테이너 기준으로 위치 */
  top: 15%; /* 일정등록 모달 안에 위치하도록 조정 */
  left: 50%;
  transform: translate(-50%, 0); /* 가로 정렬 중앙 */
  width: 90%; /* 일정등록 모달 크기에 맞추어 */
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
}

.batch-modal-content {
  /* background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 350px;
  max-height: 360px;
  overflow-y: auto; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;  */
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-height: 360px; 
  
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.user-list-wrapper {
  max-height: 360px; /* 사용자 목록 10명 기준 (36px x 10) */
  overflow-y: auto; /* 내부 스크롤 활성화 */
  border: 1px solid #ccc; /* 테두리 추가 */
  border-radius: 5px; /* 테두리 둥글게 */
  padding: 5px; /* 내부 여백 */
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-item {
  padding: 5px 8px; /* 줄 간격 줄이기 */
  font-size: 12px; /* 폰트 크기 12px로 조정 */
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.user-item:hover {
  background-color: #f5f5f5;
}

.close-btn {
  /* margin-top: 50px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer; */

  margin-top: 20px; /* 리스트와 버튼 간격 */
  padding: 8px 16px; /* 버튼 크기 조정 */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* 텍스트 크기 */
  align-self: center; /* 버튼을 중앙으로 정렬 */
}

.close-btn:hover {
  background-color: #0056b3;
}

.batch-list {
  /* list-style: none; 
  padding: 0; 
  margin: 0;  */
  list-style: none; /* 기본 리스트 스타일 제거 */
  padding: 0; /* 기본 padding 제거 */
  margin: 0; /* 기본 margin 제거 */
  border: 1px solid #ccc; /* 테두리 추가 */
  border-radius: 5px; /* 둥근 모서리 */
  max-height: 230px; /* 최대 높이 설정 */
  overflow-y: auto; /* 세로 스크롤 활성화 */
}



.batch-item {
  padding: 5px 8px; /* 줄 간격 줄이기 */
  font-size: 12px; /* 폰트 크기 12px로 조정 */
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.batch-item:hover {
  background-color: #f5f5f5;
}

.batch-item.selected {
  background-color: rgb(110, 113, 189); /* 선택된 항목 배경색 */
  color: white; /* 선택된 항목 글자색 */
  font-weight: bold; /* 선택된 항목 글자 강조 */
  border-radius: 5px; /* 모서리 둥글게 (선택 사항) */
}

.placeholder-text {
  color: #ff0000; /* 텍스트 색상 */
  font-size: 0.8em; /* 크기 조정 */
  margin-bottom: 5px; /* 간격 추가 */
}

</style> 
