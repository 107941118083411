var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar-container"},[_c('Fullcalendar',{ref:"fullCalendar",attrs:{"plugins":_vm.calendarPlugins,"header":{
      left: 'dayGridMonth, timeGridWeek, timeGridDay, listWeek',
      center: 'title',
      right: 'prev today next'
    },"buttonText":{
      today: '오늘',
      month: '월별',
      week: '주별',
      day: '일별',
      list: '리스트'
    },"weekends":true,"selectable":true,"editable":true,"events":_vm.events,"locale":'ko',"eventRender":_vm.customEventRender},on:{"select":_vm.handleSelect,"eventClick":_vm.handleEventClick,"eventResize":_vm.updateEvent,"eventDrop":_vm.updateEvent}}),(_vm.showModal)?_c('modal',{attrs:{"isVisible":_vm.showModal,"event":_vm.selectedEvent},on:{"close":function($event){_vm.showModal = false},"add-event":_vm.saveEvent}}):_vm._e(),(_vm.showEventModal)?_c('event-modal',{attrs:{"isVisible":_vm.showEventModal,"event":_vm.selectedEvent},on:{"close":function($event){_vm.showEventModal = false},"update-event":_vm.updateEvent,"delete-event":_vm.deleteEvent}}):_vm._e(),(_vm.showCommentsModal)?_c('comments-modal',{on:{"close":function($event){_vm.showCommentsModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }